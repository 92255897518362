import Image from "next/image";
import { HotRelease } from "__generated__/types/graphql.types";
import getCurrentWeek from "lib/currentWeek";

export default function HotReleaseSection({ hotRelease }: { hotRelease: HotRelease }) {
  return (
    <>
      <h2
        className="inline-flex items-center font-display text-3xl font-extrabold text-stone-900 md:text-white"
        id="join-heading"
      >
        Hot Release
        <span className="ml-4 inline-flex items-center justify-center rounded-full border border-stone-900 px-3 py-1 font-body text-sm font-medium md:border-white">
          Week {getCurrentWeek()}
        </span>
      </h2>
      <div className="aspect-h-1 aspect-w-1">
        <div className="mt-4 flex h-full w-full items-end overflow-hidden rounded-2xl shadow-md lg:mt-8">
          <div className="relative z-20 flex h-1/2 w-full flex-col justify-end bg-gradient-to-b from-transparent to-black p-6">
            <div className="font-display text-3xl font-extrabold text-white">{hotRelease.track}</div>
            <div className="font-display text-xl text-white">{hotRelease.artist}</div>
          </div>
          <div className="absolute inset-0 z-10">
            <Image
              src={hotRelease.album_art!}
              fill
              className="object-cover"
              alt={`${hotRelease.artist} - ${hotRelease.track}`}
            />
          </div>
        </div>
      </div>
    </>
  );
}
