import { ArrowNarrowRightIcon, ChevronRightIcon } from "@heroicons/react/outline";
import Image from "next/image";
import Link from "next/link";
import type { Podcast as PodcastType } from "__generated__/types/graphql.types";
import { ButtonLink } from "components/Button";

export default function Podcasts({ podcasts }: { podcasts: PodcastType[] }) {
  return (
    <div className="mx-auto max-w-7xl px-2">
      <div className="flex justify-between">
        <h2 className="font-display text-3xl font-extrabold">Podcasts</h2>
        <span className="hidden sm:block">
          <ButtonLink
            href="/podcasts"
            title="Alle podcasts"
            icon={<ChevronRightIcon className="h-5 w-5" />}
          />
        </span>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-8 md:grid-cols-4 lg:mt-8">
        {podcasts.map(podcast => (
          <Podcast podcast={podcast} key={podcast._id} />
        ))}
      </div>
    </div>
  );
}

function Podcast({ podcast }: { podcast: PodcastType }) {
  return (
    <Link href={`/podcasts/${podcast._slug}`}>
      <div className="aspect-h-1 aspect-w-1">
        <div
          className="group flex items-end overflow-hidden rounded-2xl shadow-md"
          style={{
            WebkitMaskImage: "-webkit-radial-gradient(white, black)",
          }}
        >
          <div className="relative z-20 flex h-1/2 w-full flex-col justify-end bg-gradient-to-b from-transparent to-black p-4 lg:p-6">
            <div className="font-display text-xl font-extrabold text-white transition-transform duration-150 lg:text-3xl lg:group-hover:-translate-y-8">
              {podcast.name}
            </div>
          </div>
          <div className="absolute bottom-0 left-0 z-30 hidden p-6 pb-5 lg:block">
            <ArrowNarrowRightIcon className="h-8 w-8 -translate-x-16 text-white transition-transform duration-150 group-hover:translate-x-0" />
          </div>
          <div className="absolute inset-0 z-10">
            <Image src={podcast.image![0].url!} fill className="object-cover" alt={podcast.name ?? ""} />
          </div>
        </div>
      </div>
    </Link>
  );
}
