import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { NewsPost } from "__generated__/types/graphql.types";

export default function Article({ article, highlight = false }: { article: NewsPost; highlight?: boolean }) {
  return (
    <Link href={`/nieuws/artikel/${article._slug}`}>
      <div
        className="group aspect-h-9 aspect-w-16 relative cursor-pointer flex-col justify-end overflow-hidden rounded-2xl bg-black shadow-xl"
        style={{
          WebkitMaskImage: "-webkit-radial-gradient(white, black)",
        }}
      >
        <div className="absolute inset-0">
          {!!article.thumbnail && (
            <Image
              src={article.thumbnail[0].url!}
              alt={article.title ?? ""}
              fill
              className="object-cover transition-transform duration-200 lg:group-hover:scale-110"
              draggable={false}
            />
          )}
        </div>
        <div className="z-10 h-full w-full">
          <div className="absolute left-0 right-0 bottom-0 z-10 flex h-3/4 flex-col justify-end bg-gradient-to-b from-transparent to-black p-4 xl:h-1/2">
            <div className="font-body text-sm text-white">
              <time dateTime={article._publish_on}>
                {Intl.DateTimeFormat("nl-NL", {
                  month: "long",
                  day: "numeric",
                }).format(new Date(article._publish_on!))}
              </time>
              {!!article.category && (
                <>
                  {" - "}
                  <span>{article.category?.[0]?.title}</span>
                </>
              )}
            </div>
            <h3
              className={classNames(
                "mt-1 max-w-full whitespace-pre-line font-display font-extrabold text-white",
                {
                  "text-3xl": highlight,
                  "overflow-hidden text-ellipsis whitespace-nowrap text-xl": !highlight,
                },
              )}
            >
              {article.title}
            </h3>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function ArticleList({ article }: { article: NewsPost }) {
  return (
    <Link href={`/nieuws/artikel/${article._slug}`}>
      <div className="grid grid-cols-4 items-center gap-8 overflow-hidden rounded-2xl px-4 py-4 hover:bg-black hover:bg-opacity-10">
        <div className="col-span-3 flex flex-1 flex-col justify-center">
          <div className="font-body text-sm text-stone-900">
            <time dateTime={article._publish_on}>
              {Intl.DateTimeFormat("nl-NL", {
                month: "long",
                day: "numeric",
              }).format(new Date(article._publish_on!))}
            </time>
            {!!article.category && (
              <>
                {" - "}
                <span>{article.category?.[0]?.title}</span>
              </>
            )}
          </div>
          <h3 className="mt-1 truncate font-display text-lg font-extrabold text-stone-900">
            {article.title}
          </h3>
          <p className="mt-1 text-sm text-stone-900 line-clamp-2">{article.snippet}</p>
        </div>
        <div className="relative w-full overflow-hidden rounded-2xl">
          <div className="aspect-h-9 aspect-w-16">
            {!!article.thumbnail && (
              <Image
                src={article.thumbnail[0].url!}
                alt={article.title ?? "thumbnail"}
                fill
                className="object-cover"
                draggable={false}
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
