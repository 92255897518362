import classNames from "classnames";
import Link from "next/link";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, ReactNode } from "react";

const sizes = {
  large: "text-lg px-8 h-16 rounded-lg",
  regular: "textmd px-4 py-2",
  small: "text-sm px-4 py-2",
  xs: "text-sm px-4 py-1",
};

const variants = {
  primary: "bg-secondary text-white",
  secondary: "bg-primary text-white",
};

export type ButtonProps = {
  icon?: ReactNode;
  iconAlign?: "left" | "right";
  title: ReactNode | JSX.Element;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isDisabled?: boolean;
};

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "title">
>(function Button(
  {
    icon = null,
    iconAlign = "right",
    title,
    className,
    variant = "primary",
    size = "regular",
    isDisabled,
    ...props
  }: ButtonProps & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "title">,
  ref,
) {
  return (
    <button
      ref={ref}
      className={classNames(
        "relative inline-flex items-center justify-center overflow-hidden rounded-2xl px-4 py-2 font-display font-extrabold leading-5 shadow-md transition duration-150 hover:shadow-lg",
        variants[variant],
        sizes[size],
        className,
        {
          "opacity-50": isDisabled,
        },
      )}
      disabled={isDisabled}
      {...props}
    >
      {!!icon && iconAlign === "left" && <span className="mr-2">{icon}</span>}
      {title}
      {!!icon && iconAlign === "right" && <span className="ml-2">{icon}</span>}
    </button>
  );
});

export default Button;

export type ButtonLinkProps = {
  href: string;
} & Omit<ButtonProps, "isLoading">;

export const ButtonLink = forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps & AnchorHTMLAttributes<HTMLAnchorElement>
>(function ButtonLink(
  {
    icon = null,
    iconAlign = "right",
    title,
    href,
    className,
    variant = "primary",
    size = "regular",
    ...props
  }: ButtonLinkProps & AnchorHTMLAttributes<HTMLAnchorElement>,
  ref,
) {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <Link
        href={href}
        ref={ref}
        className={classNames(
          "relative inline-flex items-center justify-center overflow-hidden rounded-2xl px-4 py-2 font-display font-extrabold leading-5 shadow-md transition duration-150 hover:shadow-lg",
          variants[variant],
          sizes[size],
          className,
        )}
        {...props}
      >
        {!!icon && iconAlign === "left" && <span className="mr-2">{icon}</span>}
        {title}
        {!!icon && iconAlign === "right" && <span className="ml-2">{icon}</span>}
      </Link>
    </span>
  );
});
