import { ChevronRightIcon } from "@heroicons/react/outline";
import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Podcasts from "./index/components/Podcasts";
import type {
  HotReleases,
  NewsPosts,
  Page,
  Podcasts as PodcastsType,
} from "__generated__/types/graphql.types";
import Article from "components/Article";
import { ButtonLink } from "components/Button";
import Footer from "components/Footer";
import { GlobalSettingsContext } from "hooks/useGlobalSettings";
import { revalidateTimeout } from "lib/constants";
import getPageProps from "lib/getPageProps";
import { prepr } from "lib/prepr";
import Header from "pages/index/components/Header";
import HotReleaseSection from "pages/index/components/HotReleaseSection";
import SongHistorySection from "pages/index/components/SongHistorySection";
import { PropsWithGlobalSettings } from "types/types";

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const preprData = await prepr
    .graphqlQuery(
      `query {
    page : Page(slug: "index") {
        _id
        _publish_on
        _changed_on
        _slug

        page_title
        page_description
        meta_title
        meta_description
        no_index

        banners {
            _id
            link
            image {
                _id
                url
                description
                caption
            }
        }
    }
    
    posts : NewsPosts (limit: 6 sort: publish_on_DESC) {
        items {
            _id
            _publish_on
            _changed_on
            _slug
            _read_time

            title
            snippet
            thumbnail {
                _id
                url
                description
            }
            category {
                _id
                title
            }
        }
    }

    hotReleases : HotReleases (limit: 1 sort: publish_on_DESC) {
        items {
            _id
            _slug

            artist
            start_date
            track
            album_art
            spotify_embed {
                url
            }
        }
    }

    podcasts : Podcasts (limit: 4 sort: publish_on_DESC) {
        items {
            _id
            _slug

            name
            color
            description
            show_on_homepage
            rss_feed
            image {
                _id
                url
                description
            }
        }
    }
      }`,
    )
    .fetch();

  return {
    props: {
      page: preprData.data.page,
      posts: preprData.data.posts,
      hotReleases: preprData.data.hotReleases,
      podcasts: preprData.data.podcasts,
      ...(await getPageProps(context, {
        namespaces: ["home"],
      })),
    },
    revalidate: revalidateTimeout,
  };
};

export default function Home({
  page,
  posts,
  hotReleases,
  podcasts,
  globalSettings,
}: PropsWithGlobalSettings<{
  page: Page;
  posts: NewsPosts;
  hotReleases: HotReleases;
  podcasts: PodcastsType;
}>) {
  return (
    <GlobalSettingsContext.Provider value={globalSettings}>
      <Head>
        <title>{`${page.page_title} | ${globalSettings.website_title}`}</title>
        <meta property="og:title" content={page.meta_title} key="title" />
        <meta name="og:image" content="/favicon.png" key="ogImage" />
        <meta name="og:type" content="website" key="ogType" />
        <meta name="og:description" content={page.meta_description} key="ogDescription" />

        <meta name="twitter:title" content={page.meta_title} />
        <meta name="twitter:description" content={page.meta_description} key="twitterDescription" />
        <meta name="twitter:image" content="/favicon.png" key="twitterImage" />
        {page.no_index && <meta name="robots" content="noindex" key="noIndex" />}
      </Head>

      <Header page={page} />

      <main className="space-y-16 lg:space-y-24">
        <div className="mx-auto mt-8 max-w-7xl px-2 lg:mt-24">
          <div className="flex justify-between">
            <h2 className="font-display text-3xl font-extrabold">Laatste updates</h2>
            <span className="hidden sm:block">
              <ButtonLink
                href="/nieuws"
                title="Meer updates"
                icon={<ChevronRightIcon className="h-5 w-5" />}
              />
            </span>
          </div>
          <div className="mt-4 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:mt-8 lg:grid-cols-3 lg:gap-8">
            {posts.items?.map(post => (
              <Article key={post._id} article={post} />
            ))}
          </div>
        </div>

        <div className="relative w-full">
          <div className="absolute inset-0 h-48 w-full skew-y-2 bg-secondary lg:-mt-4" />
          <div className="relative z-10 mx-auto max-w-7xl space-y-8 px-2 pt-8 md:grid md:grid-cols-3 md:gap-4 md:space-y-0 lg:gap-12">
            <div className="col-span-2">
              <SongHistorySection />
            </div>
            <div className="col-span-1">
              {hotReleases?.items && <HotReleaseSection hotRelease={hotReleases?.items?.[0]} />}
            </div>
          </div>
        </div>

        <Podcasts podcasts={podcasts?.items ?? []} />
      </main>

      <Footer />
    </GlobalSettingsContext.Provider>
  );
}
