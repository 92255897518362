import classNames from "classnames";
import Image from "next/image";
import { SongHistory, usePlayerStore } from "hooks/usePlayerStore";

export default function SongHistorySection() {
  const store = usePlayerStore();

  return (
    <>
      <h2 className="font-display text-3xl font-extrabold text-white">Recent afgespeeld</h2>
      <div className="mt-4 max-w-2xl space-y-4 lg:mt-8">
        {store.songHistory.map((item, index) => (
          <Song item={item} key={item.played_at} index={index} />
        ))}
      </div>
    </>
  );
}

function Song({ item, index }: { item: SongHistory; index: number }) {
  return (
    <div className="mt-1 flex h-24 flex-row items-center overflow-hidden rounded-2xl border border-stone-100 bg-white pr-8 shadow-md">
      <div className="relative h-24 h-full w-24">
        <Image src={item.song.art} alt={item.song.text} fill className="object-cover" />
      </div>
      <div
        className="mx-4 flex flex-1 flex-col items-start justify-center overflow-hidden"
        title={item.song.text}
      >
        <div className="max-w-full truncate font-display text-2xl font-extrabold">{item.song.title}</div>
        <div className="text-md max-w-full truncate font-display">{item.song.artist}</div>
      </div>
      <div
        className={classNames(
          "text-md inline-flex items-center justify-center rounded-full px-3 py-1 font-display font-extrabold text-white",
          {
            "bg-secondary": index % 2,
            "bg-primary": !(index % 2),
          },
        )}
      >
        {Intl.DateTimeFormat("nl-NL", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(0).setUTCSeconds(item.played_at))}
      </div>
    </div>
  );
}
