import Link from "next/link";
import { MenuItem } from "__generated__/types/graphql.types";
import {
  SvgIconFacebook,
  SvgIconInstagram,
  SvgIconJuke,
  SvgIconTunein,
  SvgIconTwitter,
  SvgIconYoutube,
} from "assets/images";
import useGlobalSettings from "hooks/useGlobalSettings";

export default function Footer() {
  const globalSettings = useGlobalSettings();

  return (
    <footer className="mt-16 bg-stone-100 lg:mt-24" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-2 lg:py-16">
        <div className="space-y-8 xl:grid xl:grid-cols-3 xl:gap-8 xl:space-y-0">
          <div className="space-y-4 xl:col-span-1">
            <h3 className="text-md font-display font-extrabold text-stone-900">Over ZTACK</h3>
            <p className="text-md whitespace-pre-line text-stone-700">{globalSettings?.footer_description}</p>
          </div>
          <div className="space-y-4 xl:col-span-1">
            <h3 className="text-md font-display font-extrabold text-stone-900">Meer ZTACK</h3>
            <ul className="text-md space-y-2 text-stone-700">
              {globalSettings?.footer_navigation?.[0]?.items?.map((item, index) => {
                item = item as MenuItem;

                return (
                  <li key={index}>
                    {item.page?.[0] && (
                      <Link href={`/${item.page[0]._slug}`} className="hover:font-medium">
                        {item.label}
                      </Link>
                    )}
                    {item.link && (
                      <a href={item.link.url} title={item.link.body} className="hover:font-medium">
                        {item.label}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="space-y-4 xl:col-span-1">
            <h3 className="text-md font-display font-extrabold text-stone-900">Luister ZTACK</h3>
            <div className="flex flex-row space-x-8">
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.juke_url}
                  target="_blank"
                  title="Luister ZTACK op JUKE.nl"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconJuke className="h-6 w-6" />
                </a>
              </div>
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.tunein_url}
                  target="_blank"
                  title="Luister ZTACK op TuneIn"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconTunein className="h-6 w-6" />
                </a>
              </div>
            </div>
            <h3 className="text-md font-display font-extrabold text-stone-900">Volg ons</h3>
            <div className="flex flex-row space-x-8">
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.instagram_url}
                  target="_blank"
                  title="Volg ZTACK op Instagram"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconInstagram className="h-6 w-6" />
                </a>
              </div>
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.twitter_url}
                  target="_blank"
                  title="Volg ZTACK op Twitter"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconTwitter className="h-6 w-6" />
                </a>
              </div>
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.youtube_url}
                  target="_blank"
                  title="Abonneer op ons YouTube kanaal"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconYoutube className="h-6 w-6" />
                </a>
              </div>
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.facebook_url}
                  target="_blank"
                  title="Like ons op Facebook"
                  rel="noreferrer"
                  className="text-stone-900 transition-colors duration-150 hover:text-stone-500"
                >
                  <SvgIconFacebook className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 justify-between space-y-2 border-t border-stone-300 pt-8 text-center text-sm font-medium text-stone-500 lg:flex lg:space-y-0 lg:text-right">
          <p className="block">
            &copy; {new Date().getFullYear()} ZTACK, een samenwerking tussen{" "}
            <a
              href="http://radiocoach.nl"
              target="_blank"
              className="transition-colors duration-150 hover:text-stone-900"
              rel="noreferrer"
            >
              Radiocoach
            </a>{" "}
            en{" "}
            <a
              href="https://deradiofabriek.nl"
              target="_blank"
              className="transition-colors duration-150 hover:text-stone-900"
              rel="noreferrer"
            >
              De Radiofabriek
            </a>
            .
          </p>
          <p className="block">
            Website ontwikkeld door{" "}
            <a
              href="https://realify.nl"
              target="_blank"
              className="text-[#FF5200] transition-colors duration-150 hover:text-stone-900"
              rel="noreferrer"
            >
              Realify
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
